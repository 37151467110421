import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";

export const Table = (props) => {
  const columns = props.columns;
  const data = props.data;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data
  });

  return (
    <Styles>
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                        </th>
                                  ))}
            </tr>
                      ))}
            </thead>
    
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
            <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                                return (
                        <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                        </td>
                                  )
                              })}
            </tr>
                      );
                  })}
            </tbody>
        </table>
    </Styles>
    );
};

const Styles = styled.div`
  // style for the body
  padding-left: 50px;
  padding-top: 5px;
  // style for the whole table
  table {
    border-spacing: 0;
    border: 0.2rem solid black;

    //  style for every column
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 0.1rem solid black;
      border-right: 0.1rem solid black;
      text-align: right;

      :last-child {
        border-right: 0;
      }
    }
    // style for every header
    th {
      padding: 0.5rem;
      border-bottom: 0.1rem dashed black;
      border-right: 0.1rem solid black;
    }
  }
`;