import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

export const DaySale = (props) => {

  // 表示する当日の売上金額をdailySummaryから取得
  let data = 0;
  if(props.length !== 0){
    const now = new Date();

    const yyyymmddDate = now.toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit",
    day: "2-digit"}).replaceAll('/', '-');
    const tmp = props.dailySummary.filter(s => s.date === yyyymmddDate);
    if(tmp != null && tmp.length > 0){
      data = tmp[0].price;
    }

  }
  return  <>{props.length !== 0 && 
    // <CardContainer>
    //   <Title>本日の売上</Title>
    //   <Amount>¥{data.toLocaleString()}</Amount>
    //   {/* <GrowthRate>+15%</GrowthRate> */}
    // </CardContainer>
    <>
          <Title>本日の売上</Title>
          <Typography component="p" variant="h4">
          ¥{data.toLocaleString()}
          </Typography>


          </>
  }</>;
};

// カードのコンテナ
// const CardContainer = styled.div`
//   margin-left:20px;
//   border: 1px solid #555;
//   background-color: #333; // ダークグレーの背景
//   color: #fff; // ホワイトのテキストカラー
//   border-radius: 10px; // 角を丸く
//   padding: 20px; // 内側の余白
//   width: 350px; // カードの幅
//   height:185px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // シャドウの追加
//   display: flex; // フレックスボックスの使用
//   flex-direction: column; // 子要素を縦に並べる
//   align-items: flex-start; // 子要素を左寄せに
// `;

// タイトルのスタイル
const Title = styled.h3`
  margin: 0 0 10px 0; // 下の余白
  font-size: 1.8rem; // 仮のフォントサイズ
`;

// 金額のスタイル
// const Amount = styled.div`
//   font-size: 1.8rem; // フォントサイズ
//   font-weight: bold; // 太字
//   margin-bottom: 10px; // 下の余白
// `;

// 増加率のスタイル
// const GrowthRate = styled.div`
//   font-size: 0.9rem; // フォントサイズ
//   color: #aaa; // 色を少し薄く
//   margin-bottom: 20px; // 下の余白
// `;