import React from 'react';
import styled from 'styled-components';

// モーダルコンポーネント
export const OrderModal = ({ order, onClose }) => {

   /**
   * 支払いステータスの日本語化
   * 
   * @param {string} statusCode 
   * @returns {string} 
   */
   const translateFinancialStatus = (statusCode) => {
    switch ( statusCode ) {
      case 'AUTHORIZED':
          return "承認済み";
      case 'EXPIRED':
          return "期限切れ";
      case 'PAID':
          return "支払い済み";
      case 'PARTIALLY_PAID':
          return "一部支払い済み";
      case 'PARTIALLY_REFUNDED':
          return "一部返金";
      case 'PENDING':
          return "保留";
      case 'REFUNDED':
            return "払い戻し";
      case 'VOIDED':
            return "無効";
      default :
          return statusCode;
    }
  };

  /**
   * 支払い方法の日本語化
   * 
   * @param {string} name 
   * @returns {string} 
   */
  const translateGatewayNames = (name) => {
    switch ( name ) {
      case 'cod, cash on delivery':
          return "代金引換";
      case 'manual':
          return "手動決済";
      case 'shopify_payment':
          return "クレジット決済";
      default :
          return name;
    }
  };

  /**
   * 支払い方法の日本語化
   * 
   * @param {string} name 
   * @returns {string} 
   */
  const translateFulfillmentStatus = (status) => {
    switch ( status ) {
      case 'FULFILLED':
          return "完了";
      case 'IN_PROGRESS':
          return "進行中";
      case 'ON_HOLD':
          return "保留中";
      case 'OPEN':
          return "オープン";
      case 'PARTIALLY_FULFILLED':
          return "一部完了";
      case 'PENDING_FULFILLMENT':
          return "一部保留";
      case 'RESTOCKED':
          return "返品済み";              
      case 'SCHEDULED':
          return "計画済み";
      case 'UNFULFILLED':
          return "未着手";
  
      default :
          return status;
    }
  };
  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>×</CloseButton>
        <ModalItem>
            <ItemLabel>・注文番号</ItemLabel>
            <ItemValue>{order.name}</ItemValue>
        </ModalItem>
        <ModalItem>
            <ItemLabel>・支払方法</ItemLabel>
            <ItemValue>{translateGatewayNames(
                    order.paymentGatewayNames != null
                    && order.paymentGatewayNames.length > 0 ? order.paymentGatewayNames[0] : '')}</ItemValue>
            <ItemValue>{translateFinancialStatus(order.displayFinancialStatus)}</ItemValue>
        </ModalItem>
        <ModalItem>
            <ItemLabel>・ステータス</ItemLabel>
            <ItemValue>{translateFulfillmentStatus(order.displayFulfillmentStatus)}</ItemValue>
        </ModalItem>
        <ModalItem>
            <ItemLabel>・購入金額</ItemLabel>
            <ItemValue>{(order.note != null && order.note.startsWith('Order from Shopee') ? 'S$' : '')
                + order.totalPriceSet.shopMoney.amount 
                + (order.note != null && order.note.startsWith('Order from Shopee') ? ''
                  : order.totalPriceSet.shopMoney.currencyCode === 'JPY'
                  ? '円' : order.totalPriceSet.shopMoney.currencyCode)} </ItemValue>
        </ModalItem>
        <ModalItem>
            <ItemLabel>・サイト名</ItemLabel>
            <ItemValue>{order.note}</ItemValue>
        </ModalItem>
        <ModalItem>
            <ItemLabel>・追跡番号</ItemLabel>
            <ItemValue>{order.fulfillments.length !== 0
                  && order.fulfillments.trackingInfo != null
                  && order.fulfillments[0].trackingInfo.length !==0 ?
                  order.fulfillments[0].trackingInfo[0].number : ''}</ItemValue>
        </ModalItem>
        <ModalItem>
            <ItemLabel>・配送先住所</ItemLabel>
            <ItemValue>{order.shippingAddress != null ? order.shippingAddress.city : null}</ItemValue>
        </ModalItem>
      </ModalContainer>
    </ModalBackdrop>
  );
};

// モーダルの背景スタイル
const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

// モーダル本体のスタイル
const ModalContainer = styled.div`
  background-color: #222;
  padding: 20px;
  border-radius: 10px;
  width: 300px; // モーダルの幅は適宜調整してください
  max-height: 90vh;
  overflow-y: auto; // 縦に長いコンテンツのスクロールを可能にする
  color: #fff;
  position: relative; // CloseButton の絶対位置の基準点
`;

// モーダルを閉じるボタンのスタイル
const CloseButton = styled.button`
  background: #333;
  border: none;
  color: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border-radius:14px;
  font-size: 1.5rem; // ボタンのサイズを調整
`;

// モーダル内の各項目のスタイル
const ModalItem = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px solid #555; // 区切り線を追加
  padding-bottom: 10px; // 線とテキストの間にスペースを追加
`;

// モーダル内のラベルのスタイル
const ItemLabel = styled.span`
  font-weight: bold;
  display: block; // ブロック要素として表示
  margin-bottom: 5px; // 値との間に余白を設ける
  font-size: 1.1em; // ラベルの文字サイズを大きくする
`;

// モーダル内の値のスタイル
const ItemValue = styled.span`
  display: block; // ブロック要素として表示
  margin-left:20px;
  font-size: 0.9em; // 値の文字サイズを調整
`;

// モーダルを表示するためのボタン
// const ModalOpenButton = styled.button`
//   background: #333;
//   color: #fff;
//   border: none;
//   padding: 10px;
//   margin-bottom: 20px;
//   cursor: pointer;
// `;