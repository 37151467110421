// import React, { useState } from 'react';
import styled from 'styled-components';
// import { MdArrowDropDown } from 'react-icons/md';

// コンポーネント定義
export const Header = (props) => {
  // const [searchQuery, setSearchQuery] = useState('');
  const {title} =props;
  // const handleSearch = () => {
  //   // 検索処理をここに実装
  //   console.log(`Searching for: ${searchQuery}`);
  // };

  return (
    <>
      <HeaderContainer>
        <Title>{title}</Title>
      </HeaderContainer>
    </>
  );
};

// スタイル定義
const HeaderContainer = styled.header`
  display: flex;
  justify-content:center;
  color: #fff; // テキストの色を白に
  padding: 15px; // 余白を追加
`;

// const SearchBar = styled.div`
//   align-items: center;
// `;

// const SearchInput = styled.input`
//   flex-grow: 1;
//   padding: 10px 10px; // 内側の余白を調整
//   margin-right: 10px; // 右の余白を設定
//   border: 1px solid #555; // 境界線を指定
//   border-radius: 4px; // 境界線の丸みを指定
//   background: #333; // 背景色を指定
//   color: #fff; // テキストの色を白に
//   width:40vh;
// `;

// const SearchButton = styled.button`
//   padding: 10px 10px; // 内側の余白を調整
//   border: none;
//   border-radius: 4px; // 境界線の丸みを指定
//   background: #555; // ボタンの背景色
//   color: white;
//   cursor: pointer;
//   transition: background-color 0.3s; // 背景色の遷移をスムーズに
//   &:hover {
//     background: #666; // ホバー時の背景色
//   }
// `;

const Title = styled.div`
  padding: 10px 10px; // 内側の余白を調整
  margin-left:50px;
  align-items: center;
  text-align:center;
`

// const UserProfileContainer = styled.div`
//   margin-left: auto;
//   display: flex;
//   background: #121212; // 背景色
//   padding: 10px 10px; // 内側の余白
//   border-radius: 20px; // 角丸の設定
// `;

// const UserImage = styled.img`
//   width: 32px; // 画像のサイズ
//   height: 32px; // 画像のサイズ
//   border-radius: 50%; // 画像を丸くする
//   margin-right: 20px; // 右の余白
// `;

// const UserName = styled.span`
//   color: white; // テキストの色
//   font-size: 14px; // フォントサイズ
// `;

// const DropDownIcon = styled(MdArrowDropDown)`
//   color: white; // アイコンの色
//   font-size: 24px; // アイコンのサイズ
//   cursor: pointer; // ホバーしたときにカーソルをポインターに
// `;