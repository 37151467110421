import { Home } from "../components/pages/Home";
import { OrderList } from "../components/pages/OrderList";
import { StockList } from "../components/pages/StockList";
import { Analytics } from "../components/pages/Analytics";
import { InfluencerList } from "../components/pages/InfluencerList";
import { Theme } from "../components/pages/Theme";

export const HomeRoutes = [
    {
        path: "home",
        title:"ホーム",
        children: <Home/>
    },
    {
        path: "order_list",
        title:"受注一覧",
        children: <OrderList/>
    },
    {
        path: "stock_list",
        title:"在庫一覧",
        children: <StockList/>
    },
    {
        path: "analytics",
        title: "分析",
        children: <Analytics/>
    },
    {
        path: "influencer_list",
        title: "インフルエンサー",
        children: <InfluencerList/>
    },
    {
        path: "theme",
        title: "テーマ",
        children: <Theme/>
    }
];