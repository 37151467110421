import { memo } from "react";
import { Routes, Route } from "react-router-dom";
import { Login } from "../components/pages/Login"
import { HomeRoutes } from "./HomeRoutes";
import { Layout } from "../components/organisms/Layout";
import { AuthProvider } from "../providers/AuthContext";
import { ProtectedRoute } from "../providers/ProtectedRoute";

export const Router = memo(() => {
    return (
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          {HomeRoutes.map((route) => (
            <Route
              path={route.path}
              element={
                <ProtectedRoute>
                  <Layout title={route.title}>{route.children}</Layout>
                </ProtectedRoute>
            }
            ></Route>
          ))}
        </Routes>
      </AuthProvider>
    );
  });
  