import React from 'react';
// import styled from 'styled-components';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { LineChart, axisClasses } from '@mui/x-charts';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// グラフのデータ
// const data = [
//   { name: '1月', price: 10000 },
//   { name: '2月', price: 30000 },
//   { name: '3月', price: 20000 },
//   { name: '4月', price: 27800 },
//   { name: '5月', price: 18900 },
//   { name: '6月', price: 23900 },
//   { name: '8月', price: 34900 },
//   { name: '9月', price: 44900 },
//   { name: '10月', price: 54900 },
//   { name: '11月', price: 64900 },
//   { name: '12月', price: 74900 },
// ];

// グラフコンポーネント
export const YearsGraph = (props) => {

  const theme = useTheme();

  const data = []
  for(let i = 0; i < 12; i++){
    const month = new Date();
    month.setMonth(month.getMonth() - 11 + i);

    const yyyymmMonth = month.toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit"}).replaceAll('/', '-');
    if(props.length !== 0){
      const filterData = props.monthlySummary.filter(e => e.month === yyyymmMonth);
      if(filterData.length > 0){
        data.push({"time": yyyymmMonth, "amount": filterData[0].price});
      }else{
        data.push({"time": yyyymmMonth, "amount": 0});
      }
    }
  }

  return (
    <>{props.length !== 0 &&
      <>  
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
      年間
    </Typography>
        <div style={{ width: '100%', flexGrow: 1, overflow: 'hidden' }}>
        <LineChart
          dataset={data}
          margin={{
            top: 16,
            right: 20,
            left: 70,
            bottom: 30,
          }}
          xAxis={[
            {
              scaleType: 'point',
              dataKey: 'time',
              tickNumber: 2,
              tickLabelStyle: theme.typography.body2,
            },
          ]}
          yAxis={[
            {
              label: 'Sales (円)',
              labelStyle: {
                ...theme.typography.body1,
                fill: theme.palette.text.primary,
              },
              tickLabelStyle: theme.typography.body2,
              max: 25000,
              tickNumber: 3,
            },
          ]}
          series={[
            {
              dataKey: 'amount',
              showMark: false,
              color: theme.palette.primary.light,
            },
          ]}
          sx={{
            [`.${axisClasses.root} line`]: { stroke: theme.palette.text.secondary },
            [`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary },
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translateX(-25px)',
            },
          }}
        />
      </div></>
    }</>
  );
};

// グラフコンテナのスタイリング
// const GraphContainer = styled.div`
//   width: 750px;
//   height:360px;
//   border: 1px solid #555;
//   background-color: #333;
//   margin-left:20px;
//   margin-top:10px;
//   padding: 20px;
//   border-radius: 10px;
// `;

// グラフヘッダーのスタイリング
// const GraphHeader = styled.div`
//   margin: 0;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin-bottom: 10px; // タイトルとグラフの間の余白
// `;

// ボタンとプルダウンアイコンのスタイリング
// const Button = styled.button`
//   background: none;
//   border: none;
//   color: #fff;
//   cursor: pointer;
// `;
// const DetailButton = styled.button`
//   width:70%;
//   padding: 10px 10px; // 内側の余白を調整
//   border: none;
//   border-radius: 4px; // 境界線の丸みを指定
//   background: #121212; // ボタンの背景色
//   color: white;
//   cursor: pointer;
//   transition: background-color 0.3s; // 背景色の遷移をスムーズに
//   &:hover {
//     background: #666; // ホバー時の背景色
// `