import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from './AuthContext';

export const ProtectedRoute = ({ children }) => {
  const { user } = useAuthContext();

  if (!user) {
    // ユーザーが認証されていない場合は、ログインページにリダイレクト
    return <Navigate to="/" />;
  }

  return children;
};