import React, { useState,useEffect} from 'react';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged
} from "firebase/auth";
import { auth } from "../../FirebaseConfig"
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';



export const Login = () => {
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const Navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      await signInWithEmailAndPassword(
        auth,
        mail,
        password
      );
      console.log("ログインに成功しました。")
    } catch(error) {
      alert("メールアドレスまたはパスワードが間違っています");
    }
  };
  
  /* ログインを判定する設定 */
  const [user, setUser] = useState();
  
    useEffect(() => {
      onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
      });
    });

  return (
          <>
          {user ? (Navigate("/home"),[]):(
            <>
              <LoginContainer>
                <Title>ログイン</Title>
                <InputGroup>
                  <Label>メールアドレス</Label>
                  <Input
                    id="mail"
                    type="text"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    placeholder="メールアドレスを入力してください"
                  />
                </InputGroup>
                <InputGroup>
                  <Label>パスワード</Label>
                  <Input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="パスワードを入力してください"
                  />
                </InputGroup>
                <Button onClick={handleSubmit}>ログイン</Button>
              </LoginContainer>
            </>
          )}
          </>
  );
};

const LoginContainer = styled.div`
  background: #161616; // 背景色
  border-radius: 10px; // 角の丸み
  padding: 40px; // 内側の余白
  width: 600px; // コンテナの幅
  height: 600px; // コンテナの高さ
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto; // 中央揃え
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); // シャドウの追加
`;

const InputGroup = styled.div`
  padding: 20px 0; //パディング
`;

const Title = styled.div`
  color:#ffffff; // タイトルの色
  font-size:45px; // フォントサイズ
  font-family:Montserrat; // フォントファミリー
  font-weight:300; // フォントの太さ
`;

const Label = styled.div`
  color: #f0f0f0; // ラベルの色
  padding: 10px 0; // パディング
  font-size: 15px; // フォントサイズ
  user-select: none; // 選択不可
`;

const Input = styled.input`
top: 326px; // 上端からの距離
left: 470px; // 水平位置
width: 489px; // コンテナの幅
height: 72px; // コンテナの高さ
background-color: #323232; // 背景色
border-radius: 28px; // 外側の角を丸
padding: 10px 0; // パディング
border: 0; // 線の太さ
font-size: 15px; // フォントサイズ
color: #ffffff; // 色
`;

const Button = styled.button`
  cursor: pointer; // カーソルの種類
  top: 574px; // 上端からの距離
  left: 485px; // 水平位置
  width: 481px; // コンテナの幅
  height: 78px; // コンテナの高さ
  border: 0; //線の太さ
  box-sizing: border-box; // ボックスのサイズ
  border-radius: 24px; //ボックスの角丸
  background-color: #030303; // 背景色
  color: #ffffff; // フォントカラー
  font-size: 14px; // フォントサイズ
  font-family: "Source Sans Pro"; // フォントファミリー
  line-height: 16px; // 行ボックスの高さ
  outline: none; // 行間
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // シャドウ
  padding: 10px 0px; // パディング
  margin-top:30px;
`;