import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import { WeekGraph } from '../molecules/WeekGraph';
// import { CumulativeGraph } from '../molecules/CumulativeGraph';
import { DaySale } from '../molecules/DaySale';
// import {Calendar} from '../molecules/Calendar';
// import { Dashboard } from '../molecules/Dashboard';
// import { RankingList } from '../molecules/Ranking';
import { YearsGraph } from '../molecules/YearsGraph';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { OrderList } from './OrderList';

// コンポーネント定義
export const Home = () => {

  const [orders, setOrders] = useState([]);
  // 日本時間に変換
  const jstOrders = [...orders];
  jstOrders.forEach(order => {
    // 時刻に変換
    const utcDate = new Date(order.processedAt);
    // UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
    const diff = utcDate.getTimezoneOffset() * 60 * 1000;    // -540 * 60 * 1000 = -32400000
    // toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
    const plusLocal = new Date(utcDate - diff);
    // ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
    let iso = plusLocal.toISOString();   // "2020-04-22T22:39:03.397Z"
    // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
    iso = iso.slice(0, 19) + '+09:00';    // "2020-04-22T22:39:03+09:00"
    order.processedAt = iso;
  });
  // 日ごとの売り上げの集計
  const dailySummary  = jstOrders.reduce((result, current) => {
    const element = result.find((p) => p.date === current.processedAt.split("T")[0]);
    if (element) {
      element.count ++; // count
      element.price += Number(current.totalPriceSet.shopMoney.amount); // sum
    } else {
      result.push({
        date: current.processedAt.split("T")[0],
        count: 1,
        price: Number(current.totalPriceSet.shopMoney.amount)
      });
    }
    return result;
  }, []);
  // 月ごとの売り上げの集計  
  const monthlySummary = dailySummary.reduce((result, current) => {
    const element = result.find((p) => p.month === current.date.split("-")[0] + "-"
      + current.date.split("-")[1]);
    if (element) {
      element.count ++; // count
      element.price += current.price; // sum
    } else {
      result.push({
        month: current.date.split("-")[0] + "-" +  current.date.split("-")[1],
        count: 1,
        price: current.price
      });
    }
    return result;
  }, []);
  // 年ごとの売り上げの集計  
  // const yearlySummary = monthlySummary.reduce((result, current) => {
  //   const element = result.find((p) => p.year === current.month.split("-")[0]);
  //   if (element) {
  //     element.count ++; // count
  //     element.price += current.price; // sum
  //   } else {
  //     result.push({
  //       year: current.month.split("-")[0],
  //       count: 1,
  //       price: current.price
  //     });
  //   }
  //   return result;
  // }, []);

  /**
   * ランキング情報を受注から集計
   */
  // const rankingData = orders.flatMap(order => 
  //   {if(order.lineItems.edges != null && order.lineItems.edges.length > 0){
  //     return order.lineItems.edges
  //   }else{
  //     return {node:{quantity: 0, product:{id :"", title:""}}};
  //   }}).reduce((result, current) => {
  //     if(current.node.product == null){
  //       return result;
  //     }
  //     const element = result.find((p) => p.id === current.node.product.id);
  //     if (element) {
  //       element.count ++; // count
  //       element.quantity += current.node.quantity; // sum
  //     } else {
  //       result.push({
  //         id:  current.node.product.id,
  //         count: 1,
  //         title: current.node.product.title,
  //         quantity: current.node.quantity,
  //         images: current.node.product.images.nodes
  //       });
  //     }
  //     return result;
  //   }, []).sort((a,b) => b.quantity - a.quantity);

  useEffect(() => {
    // プロキシエンドポイントへのリクエストを行う関数
    const fetchOrders = async () => {
      try {
        const proxy_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
        const response = await fetch(proxy_base_url + '/api/orders');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        console.log(data)
        console.log('プロキシエンドポイントへの接続に成功しました')
        // 取得したデータを状態にセット
        setOrders(data);
      } catch (error) {
        console.error('Failed to fetch orders:', error);
        console.log('プロキシエンドポイントへの接続に失敗しました')
      }
    };

    fetchOrders();
  }, []); // 空の依存配列を渡して、コンポーネントのマウント時にのみ実行されるように
  return(
    <>
<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <YearsGraph monthlySummary={monthlySummary}/>
                </Paper>
              </Grid>
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  {/* <RankingList rankingData={rankingData}/> */}
                  <DaySale dailySummary={dailySummary}/>
                </Paper>
              </Grid>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <OrderList />
                </Paper>
              </Grid>
            </Grid>
          </Container>

    
      {/* <FlexContainer>
        <GridContainer>
          <WeekGraph dailySummary={dailySummary}/>
          <CumulativeGraph yearlySummary={yearlySummary}/>
          <FlexContainer>
            <DaySale dailySummary={dailySummary}/>
            <Calendar/>
          </FlexContainer>
        </GridContainer>
        <GridContainer>
          <FlexContainer>
            <Dashboard/>
            <RankingList rankingData={rankingData}/>
          </FlexContainer>
          <YearsGraph monthlySummary={monthlySummary}/>
        </GridContainer>
      </FlexContainer> */}
    </>
  )
}

// const GridContainer = styled.div`
//   display:grid;
// `;

// const FlexContainer = styled.div`
//   display:flex;
// `;