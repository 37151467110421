import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaTruck } from 'react-icons/fa';
import { OrderModal } from './OrderModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// ダミーデータを生成
// const generateDummyOrders = () => {
//   let dummyOrders = [];
//   for (let i = 1; i <= 30; i++) {
//     dummyOrders.push({
//       id: i,
//       customerInfo: `顧客${i}`,
//       paymentMethod: 'クレジットカード',
//       paymentStatus: i % 2 === 0 ? '支払い済み' : '入金待ち',
//       status: i % 2 === 0 ? '出荷済' : '準備中',
//       amount: `${i * 1000}円`,
//       siteName: `サイト${i}`,
//       shipDate: '2024/01/11',
//       trackingNumber: `12345-${i}`,
//       shippingAddress: `住所${i}`,
//     });
//   }
//   return dummyOrders;
// };

export const OrderList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const ordersPerPage = 10;
  // const dummyOrders = generateDummyOrders();
  // const dummyOrdersData = dummyOrders
  const [orders, setOrders] = useState([]);
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
  const handleOpenModal = (order) => {
    setSelectedOrder(order);
  };
  const handleCloseModal = () => {
    setSelectedOrder(null);
  };
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(orders.length / ordersPerPage); i++) {
    pageNumbers.push(i);
  }
  // 現在のページに表示するオーダーの総数
  const totalOrders = orders.length;
  const currentCount = currentOrders.length;

  /**
   * 支払いステータスの日本語化
   * 
   * @param {string} statusCode 
   * @returns {string} 
   */
  const translateFinancialStatus = (statusCode) => {
    switch ( statusCode ) {
      case 'AUTHORIZED':
          return "承認済み";
      case 'EXPIRED':
          return "期限切れ";
      case 'PAID':
          return "支払い済み";
      case 'PARTIALLY_PAID':
          return "一部支払い済み";
      case 'PARTIALLY_REFUNDED':
          return "一部返金";
      case 'PENDING':
          return "保留";
      case 'REFUNDED':
            return "払い戻し";
      case 'VOIDED':
            return "無効";
      default :
          return statusCode;
    }
  };

  /**
   * 支払い方法の日本語化
   * 
   * @param {string} name 
   * @returns {string} 
   */
  const translateGatewayNames = (name) => {
    switch ( name ) {
      case 'cod, cash on delivery':
          return "代金引換";
      case 'manual':
          return "手動決済";
      case 'shopify_payment':
          return "クレジット決済";
      default :
          return name;
    }
  };

  /**
   * 支払い方法の日本語化
   * 
   * @param {string} name 
   * @returns {string} 
   */
  const translateFulfillmentStatus = (status) => {
    switch ( status ) {
      case 'FULFILLED':
          return "完了";
      case 'IN_PROGRESS':
          return "進行中";
      case 'ON_HOLD':
          return "保留中";
      case 'OPEN':
          return "オープン";
      case 'PARTIALLY_FULFILLED':
          return "一部完了";
      case 'PENDING_FULFILLMENT':
          return "一部保留";
      case 'RESTOCKED':
          return "返品済み";              
      case 'SCHEDULED':
          return "計画済み";
      case 'UNFULFILLED':
          return "未着手";
  
      default :
          return status;
    }
  };

  useEffect(() => {
    // プロキシエンドポイントへのリクエストを行う関数
    const fetchOrders = async () => {
      try {
        const proxy_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
        const response = await fetch(proxy_base_url + '/api/orders');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        console.log(data)
        console.log('プロキシエンドポイントへの接続に成功しました')
        // 取得したデータを状態にセット
        setOrders(data);
      } catch (error) {
        console.error('Failed to fetch orders:', error);
        console.log('プロキシエンドポイントへの接続に失敗しました')
      }
    };

    fetchOrders();
  }, []); // 空の依存配列を渡して、コンポーネントのマウント時にのみ実行されるように

  return (
    <>
      {selectedOrder && <OrderModal order={selectedOrder} onClose={handleCloseModal} />}
      <SummaryContainer>
        <TotalCount>総件数: {totalOrders}件</TotalCount>
        <TotalCount>表示件数: {currentCount}件</TotalCount>
      </SummaryContainer>
      <OrderListContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
              <TableCell>番号</TableCell>
              <TableCell>支払方法</TableCell>
              <TableCell>ステータス</TableCell>
              <TableCell>購入金額</TableCell>
              <TableCell>サイト名</TableCell>
              <TableCell>追跡番号</TableCell>
              <TableCell>住所</TableCell>
              <TableCell>発送ステータス</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentOrders.map((order, index) => (
              <TableRow  key={order.id}>
                <TableCell onClick={() => handleOpenModal(orders[index])}>{order.name}</TableCell>
                <TableCell> 
                  {translateGatewayNames(
                    order.paymentGatewayNames != null
                    && order.paymentGatewayNames.length > 0 ? order.paymentGatewayNames[0] : '')}
                  <br />
                  {translateFinancialStatus(order.displayFinancialStatus)}
                </TableCell>
                <TableCell>
                  <StatusBadge status={order.displayFulfillmentStatus}>{translateFulfillmentStatus(order.displayFulfillmentStatus)}</StatusBadge>
                </TableCell>
                <TableCell>{(order.note != null && order.note.startsWith('Order from Shopee') ? 'S$' : '')
                + order.totalPriceSet.shopMoney.amount 
                + (order.note != null && order.note.startsWith('Order from Shopee') ? ''
                  : order.totalPriceSet.shopMoney.currencyCode === 'JPY'
                  ? '円' : order.totalPriceSet.shopMoney.currencyCode)} </TableCell>
                <TableCell>{order.note}</TableCell>
                <TableCell>{order.fulfillments.length !== 0
                  && order.fulfillments[0].trackingInfo != null
                  && order.fulfillments[0].trackingInfo.length !==0 ?
                  order.fulfillments[0].trackingInfo[0].number : ''}</TableCell>
                <TableCell>{order.shippingAddress != null ? order.shippingAddress.city : null}</TableCell>
                {/* 配送完了があればトラックマークを表示 */}
                <TableCell>
                {order.fulfillments.length !== 0 &&
                  order.fulfillments.filter(item => item.displayStatus === 'DELIVERED'
                || item.displayStatus === 'FULFILLED').length !== 0
                  ? <FaTruck /> : '未配送'
                }
                </TableCell>
              </TableRow >
            ))}
          </TableBody>
        </Table>
        <PaginationContainer>
          {pageNumbers.map(number => (
            <PageNumber
              key={number}
              isActive={currentPage === number}
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </PageNumber>
          ))}
        </PaginationContainer>
      </OrderListContainer>
    </>
  );
};

const OrderListContainer = styled.div`
  padding: 20px;
  border-radius: 6px;
  overflow-x: auto;
  margin-left: 15px;
`;

// const Table = styled.table`
//   width: 100%;
//   border-collapse: collapse;
//   text-align: left;
// `;

// const Th = styled.th`
//   padding: 8px;
//   border-bottom: 2px solid #555;
// `;

// const Td = styled.td`
//   padding: 8px;
//   border-bottom: 2px solid #555;
// `;

// const TdName = styled.td`
//   padding: 8px;
//   border-bottom: 2px solid #555;
//   cursor:pointer;
// `

const StatusBadge = styled.span`
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${({ status }) => (status === 'FULFILLED' ? '#4CAF50' : '#FFC107')};
`;

// const Checkbox = styled.input.attrs({ type: 'checkbox' })`
//   margin-right: 10px;
// `;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const PageNumber = styled.span`
  cursor: pointer;
  padding: 5px;
  margin: 0 5px;
  background: ${({ isActive }) => (isActive ? '#555' : 'none')};
  color: ${({ isActive }) => (isActive ? '#fff' : '#ccc')};
`;

// 総件数と表示件数を表示するコンポーネント
const SummaryContainer = styled.div`
  padding: 10px 0;
  display: flex;
`;

const TotalCount = styled.span`
  margin-left:20px;
  font-size: 1rem;
`;

// 支払いステータスバッジを表示するためのコンポーネント
// const PaymentStatusBadge = styled.td`
//   display:grid;
// `;