import React from 'react';
import './App.css';
import styled from 'styled-components';
import { BrowserRouter} from 'react-router-dom';
import { Router } from './router/Router'


export default function App() {
  return (
    <BrowserRouter>
        <Router/>
    </BrowserRouter>
  );
}