import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import influencerList from '../data/influencerList.json';

export const InfluencerList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;
  const [influencers, setInfluencers] = useState([]);
  const indexOfLast = currentPage * ordersPerPage;
  const indexOfFirst = indexOfLast - ordersPerPage;
  const currentInfluencers = influencers.slice(indexOfFirst, indexOfLast);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(influencers.length / ordersPerPage); i++) {
    pageNumbers.push(i);
  }
  // 現在のページに表示するオーダーの総数
  const totalOrders = influencers.length;
  const currentCount = currentInfluencers.length;

  useEffect(() => {
    setInfluencers(influencerList);
  }, []); // 空の依存配列を渡して、コンポーネントのマウント時にのみ実行されるように

  return (
    <>
      <SummaryContainer>
        <TotalCount>総件数: {totalOrders}件</TotalCount>
        <TotalCount>表示件数: {currentCount}件</TotalCount>
      </SummaryContainer>
      <OrderListContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
              <TableCell>氏名</TableCell>
              <TableCell>連絡先</TableCell>
              <TableCell><InstagramIcon/></TableCell>
              <TableCell><FacebookIcon/></TableCell>
              <TableCell><XIcon/></TableCell>
              <TableCell><YouTubeIcon/></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentInfluencers.map((influencer, index) => (
              <TableRow key={influencer.id}>
                <TableCell>{influencer.name}</TableCell>
                <TableCell>{influencer.contact}</TableCell>
                <TableCell>{influencer.instagram ? 
                  <a href={influencer.instagram}>
                    Link
                  </a> : ""}</TableCell>
                  <TableCell>{influencer.facebook ? 
                  <a href={influencer.facebook}>
                    Link
                  </a> : ""}</TableCell>
                  <TableCell>{influencer.x ? 
                  <a href={influencer.x}>
                    Link
                  </a> : ""}</TableCell>
                  <TableCell>{influencer.youtube ? 
                  <a href={influencer.youtube}>
                    Link
                  </a> : ""}</TableCell>
              </TableRow >
            ))}
          </TableBody>
        </Table>
        <PaginationContainer>
          {pageNumbers.map(number => (
            <PageNumber
              key={number}
              isActive={currentPage === number}
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </PageNumber>
          ))}
        </PaginationContainer>
      </OrderListContainer>
    </>
  );
};

const OrderListContainer = styled.div`
  padding: 20px;
  border-radius: 6px;
  overflow-x: auto;
  margin-left: 15px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const PageNumber = styled.span`
  cursor: pointer;
  padding: 5px;
  margin: 0 5px;
  background: ${({ isActive }) => (isActive ? '#555' : 'none')};
  color: ${({ isActive }) => (isActive ? '#fff' : '#ccc')};
`;

// 総件数と表示件数を表示するコンポーネント
const SummaryContainer = styled.div`
  padding: 10px 0;
  display: flex;
`;

const TotalCount = styled.span`
  margin-left:20px;
  font-size: 1rem;
`;