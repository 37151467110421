import { useState, useEffect } from 'react';
import * as React from 'react';
import { MenuDrawer } from "../molecules/MenuDrawer"
import { Header } from "../molecules/Header"
// import styled from 'styled-components';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCookies } from 'react-cookie';


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export const ColorModeContext = React.createContext({
  colorMode: { toggleColorMode: () => {}
      ,setLight: () => {} 
      ,setDark: () => {}},
  mode: 'light',
});

export const Layout = (props) => {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  
  const {children,title} = props;
  const [cookies, setCookie] = useCookies();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const initMode = cookies.react_mode ?? (prefersDarkMode ? 'dark' : 'light');
  const [mode, setMode] = React.useState(initMode);
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      setLight: () => {
        setMode('light');
      },
      setDark: () => {
        setMode('dark');
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  /**
   * ダークモード、ライトモードの切り替え
   */
  useEffect(() => {
    document.documentElement.setAttribute('theme', mode);
    // クッキーの保存して、次回描画時に利用
    setCookie('react_mode', mode, { maxAge: 60 * 60 * 12 * 7, path: '/' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);
  const providerValue = { colorMode, mode };
    return(
      <>

<ColorModeContext.Provider value={providerValue}>
<ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {title}
            </Typography>
            <div style={{ flexGrow: 1 }}></div>
          <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          </Toolbar>
        </AppBar>
                      <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
          <MenuDrawer/>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />                      
        {/* <LayoutContent>
          <MenuDrawer/>
            <MainContent>
              <Header title = {title}/>
              {children}
            </MainContent>
        </LayoutContent> */}
        {children}
                </Box>
      </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
      </>
    )
}

// スタイルコンポーネント
// const LayoutContent = styled.div`
//   display:flex;
//   height: 100%;
//   width: 100%;
//   background: #121212; // ダークテーマの背景色に合わせる
// `;

// const MainContent = styled.div`
//   flex-grow: 1;
// `;