// import React, { useState } from 'react';
import styled from 'styled-components';
import { Table } from '../molecules/Table';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import forecastData from '../data/itemsForecast.json';
import itemsData from '../data/itemsInfo.json';
import dayjs from 'dayjs';
/*
 * npm install --save dayjs
 * npm install --save react-table
 */

const columns = [
  {Header: "商品", accessor: "product"},
  {Header: "価格", accessor: "price"},
  {Header: "予測売上個数", accessor: "demand"},
  {Header: "予測売上小計", accessor: "str_subtotal"}
];

const days = [];
const oForecastByDay = [];
const oForecastByItem = {};
const oForecastBySubtotal = [];
const term = {};

const initForecastByItem = itemsData.map(item => {
  oForecastByItem[item.item_id] = 0;
});

const getXAxis = forecastData.map((item, index) => {
  const day = dayjs(item.timestamp).format('MM/DD');
  if (!days.includes(day)) {
    days.push(day);
  }
  if (index === 0)
    term["from"] = day;
  if (index === (forecastData.length - 1))
    term["to"] = day;
  item.timestamp = day;
});

const ForecastByDay = days.map((day) => {
  const t = {};
  forecastData.map((item, index) => {
    if (item.timestamp === day) {
      const j = itemsData.findIndex(v => v.item_id === item.item_id);
      const price = item.demand * itemsData[j].price;
      t["date"] = item.timestamp;
      t["price"] = item.demand * itemsData[j].price;
      t[item.item_id] = item.demand;
      if (!oForecastByDay.find((k) => k.date === day)) {
        oForecastByDay.push(t);
      }
      if (item.demand > 0)
        oForecastByItem[item.item_id] += item.demand;
    }
  });
});

const ForecastBySubtotal = Object.keys(oForecastByItem).forEach(function(value, key){
  const t = {};
  const j = itemsData.findIndex(v => v.item_id === Number(value));
  t["product"] = itemsData[j].item_name;
  t["price"] = itemsData[j].price.toLocaleString();
  t["demand"] = oForecastByItem[value].toLocaleString();
  t["str_subtotal"] = (itemsData[j].price * oForecastByItem[value]).toLocaleString();
  t["subtotal"] = (itemsData[j].price * oForecastByItem[value]);
  oForecastBySubtotal.push(t);
});
const total = oForecastBySubtotal.reduce((sum, i) => sum + i.subtotal, 0);


export const Analytics = () => {
  return (
    <>
    <GraphContainer>
        <GraphHeader>
            <Title>売上予測・個数</Title>
            <Term>期間：{term["from"]} - {term["to"]}</Term>
        </GraphHeader>
        <GraphBody>
            <ComposedChart 
                width={1000} 
                height={600} 
                data={oForecastByDay}
                margin={{top: 10, bottom: 10}}
                >
                <CartesianGrid 
                    strokeDasharray="3 3"
                    storoke="#fff"
                    />
                <XAxis 
                    dataKey="date" />
                <YAxis 
                    type="number" />
                <Tooltip />
                <Legend verticalAlign="bottom" height={36}/>
                {itemsData.map(item => (
            <Line
                name={item.item_name}
                dataKey={item.item_id} 
                type="monotone"
                stroke={item.fill} />
                      ))}
            </ComposedChart>
    
            <Total>
            予測売上合計：{total.toLocaleString()}  
            </Total>
            <Table 
                data={oForecastBySubtotal}
                columns={columns} />
        </GraphBody>
    </GraphContainer>
    </>
    );
};

const GraphContainer = styled.div`
  border-radius: 10px;
  font-size: small;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction:column;
  justify-content: center;
`;
const GraphHeader = styled.div`
  margin: 10px auto;
`;
const GraphBody = styled.div`
  margin: 10px auto;
`;
const Title = styled.h2`
`;
const Term = styled.div`
  font-size: small;
`;
const Total = styled.div`
  font-size: 1rem;
  margin-left: 50px;
`;