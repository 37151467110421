import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
/* npm i json-loader --save-dev
 * sample created at https://json-generator.com/
 * [{id, name, isActive, image, quantity, price}]
 * 
 */
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';


export const StockList = () => {

  const [currentPage, setCurrentPage] = useState(1);
  
  //データを保持するための状態
  const [inventoryItems, setInventoryItems] = useState([]);
  const itemsNum = inventoryItems.length;
  const itemsPerPage = 10;
  const totalPages = Math.ceil(itemsNum / itemsPerPage);
  const itemAtPageFrom = (currentPage === 1) ? 1 : (currentPage - 1) * itemsPerPage;
  const itemAtPageTo = (currentPage === totalPages) ? itemsNum : currentPage * itemsPerPage;

  useEffect(() => {
    // プロキシエンドポイントへのリクエストを行う関数
    const fetchOrders = async () => {
      try {
        const proxy_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
        const response = await fetch(proxy_base_url + '/api/products');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        console.log(data)
        console.log('プロキシエンドポイントへの接続に成功しました')
        setInventoryItems(data); // 取得したデータを状態にセット
      } catch (error) {
        console.error('Failed to fetch orders:', error);
        console.log('プロキシエンドポイントへの接続に失敗しました')
      }
    };

    fetchOrders();
  }, []); // 空の依存配列を渡して、コンポーネントのマウント時にのみ実行されるように

  return (
    <>
        <InventorySummary>
            <p>{itemAtPageFrom}～{itemAtPageTo}件 ({itemsNum}件)</p>
        </InventorySummary>
        <Grid container spacing={4}>
{inventoryItems.map((item, i) => (
  <Grid item key={i} xs={12} sm={6} md={4}>
<Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={item.image != null ? item.image.url : 
            (item.product.images.nodes.length > 0 &&
              item.product.images.nodes[0].url != null ? item.product.images.nodes[0].url : "")
            } 
            alt={item.product.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          {item.product.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          在庫数:  {item.inventoryQuantity.toLocaleString()}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card></Grid>)).slice(itemAtPageFrom === 1 ? 0 : itemAtPageFrom , itemAtPageTo)}
    </Grid>
    {/* <InventoryContainer>
        <InventorySummary>
            <p>{itemAtPageFrom}～{itemAtPageTo}件 ({itemsNum}件)</p>
        </InventorySummary>
    
        <InventoryGrid>
            {inventoryItems.map(item => (
              <InventoryItemCard key={item.id}>
                  <InventoryItemImage src={item.image != null ? item.image.url : 
                  (item.product.images.nodes.length > 0 &&
                    item.product.images.nodes[0].url != null ? item.product.images.nodes[0].url : "")
                  } alt={item.product.title} />
                  <InventoryItemInfo>
                      <InventoryItemName>{item.product.title}</InventoryItemName>
                      <InventoryQuantity>
                          <InventoryItem>在庫数: </InventoryItem>
                          {item.inventoryQuantity.toLocaleString()}
                      </InventoryQuantity>
                  </InventoryItemInfo>
              </InventoryItemCard>
                    )).slice(itemAtPageFrom === 1 ? 0 : itemAtPageFrom , itemAtPageTo)}
        </InventoryGrid>
    </InventoryContainer> */}
    
    <Pagination>
        {Array.from({length: totalPages}, (_, index) => (
            <PageNumber
                key={index}
                isActive={currentPage === index + 1}
                onClick={() => setCurrentPage(index + 1)}
                >
                {index + 1}
            </PageNumber>
              ))}
    </Pagination>
    </>
    );
};

/* Inventory */
// const InventoryContainer = styled.div`
//   color: #fff;
//   font-size: smaller;
// `;
const InventorySummary = styled.div`
  padding-left: 20px;
`;
// const InventoryGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(5, 16%);
//   gap: 2%;
//   padding: 20px;
// `;
// const InventoryItemCard = styled.div`
//   background: #202020;
//   border-radius: 10px;
//   padding: 10px;
//   display: flex;
//   flex-direction: column;
// `;
// const InventoryItemImage = styled.img`
//   width: 100%;
//   height: auto;
//   object-fit: fill;
// `;
// const InventoryItemInfo = styled.div`
// `;
// const InventoryItemName = styled.h3`
// `;
// const InventoryItem = styled.span`
//   font-size: small;
// `;
// const InventoryQuantity = styled.p`
//   text-align: right;
// `;

/* Page */
const Pagination = styled.div`
  color: #000;
  display: flex;
  justify-content: center;
`;
const PageNumber = styled.span`
  margin: 10px;
  cursor: pointer;
  ${({ isActive }) =>
  isActive &&
    `
    text-decoration: underline;
    font-weight: bold;
  `}
`;
